import { Global } from '@emotion/react'
import { color, font } from './tokens'

import { useTheme } from './ThemeProvider'

export const GlobalStyles = () => {
  const theme = useTheme()
  return (
    <Global
      styles={{
        '*, html, body': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          outlineColor: color('gray-700'),
        },
        body: {
          fontFamily: font('default'),
          // text that isn't explicitly sized causes strange UI issues, so make it obvious when styles are missing.
          fontSize: '2px',
          WebkitOverflowScrolling: 'touch',
          backgroundColor: color('body-background'),
          color: color('gray-100'),

          '&:has(dialog[open])': {
            overflow: 'hidden',
          },
        },
        'svg[data-icon]': {
          verticalAlign: 'bottom',
        },
        'svg[data-icon] *': {
          vectorEffect: 'non-scaling-stroke',
        },
        a: theme.styles.Link,
        '.PSPDFKit-Container': {
          position: 'absolute',
        },
      }}
    />
  )
}
