import { EmotionCacheProvider } from './EmotionCacheContext'
import FontFaces from './FontFaces'
import { GlobalStyles } from './GlobalStyles'
import { ScreenSizeProvider } from './ScreenSize'
import { ThemeProvider } from './ThemeProvider'
import DefaultTheme from './themes/default'
import { Theme } from './themeTypes'

/** This single provider combines the theme, global styles, font faces, and screen size providers */
export function DesignSystemProvider({
  initialTheme = DefaultTheme,
  includeFonts = false,
  children,
}: {
  initialTheme?: Theme
  includeFonts?: boolean
  children: React.ReactNode
}) {
  return (
    <EmotionCacheProvider>
      <ThemeProvider theme={initialTheme}>
        {includeFonts && <FontFaces />}
        <GlobalStyles />
        <ScreenSizeProvider>{children}</ScreenSizeProvider>
      </ThemeProvider>
    </EmotionCacheProvider>
  )
}
