export default function watchMediaQueries<Keys extends string>(
  options: {
    queries: { [K in Keys]: string }
    window?: Window
  },
  notify: (current: Keys) => void,
): { current: Keys; cleanup: () => void } {
  const w = options.window || window
  const names = Object.keys(options.queries) as Keys[]
  const queries = names.map(name => w.matchMedia(options.queries[name].replace('@media ', '')))

  let current = names[0]

  const listener = () => {
    const prev = current
    queries.forEach((mq, i) => {
      if (mq.matches) {
        current = names[i]
      }
    })
    if (prev !== current) {
      notify(current)
    }
  }

  const cleanup = () => {
    queries.forEach(mq => mq.removeListener(listener))
  }

  queries.forEach(mq => mq.addListener(listener))

  listener()

  return { current, cleanup }
}
