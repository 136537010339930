import { Global } from '@emotion/react'
import * as React from 'react'

let _requireFont: __WebpackModuleApi.RequireContext
const requireFont = (filename: string) => {
  _requireFont ??= require.context('../../app/assets/fonts', false, /.*\.(woff|eot)$/)
  return _requireFont(filename)
}

const fontUrls = (fontName: string) =>
  ['woff']
    .map(format => `url('${requireFont(`./${fontName}.${format}`)}') format('${format}')`)
    .join(',')

const fontFaces = (
  fontFamily: string,
  filePrefix: string,
  weights: [React.CSSProperties['fontWeight'], string][],
) =>
  weights
    .map(
      ([weight, suffix]) => `@font-face {
  font-family: '${fontFamily}';
  font-weight: ${weight};
  src: ${fontUrls(`${filePrefix}-${suffix}`)};
}
`,
    )
    .join('\n')

let allFonts: string

export default function FontFaces() {
  allFonts ??= [
    fontFaces('Inter', 'Inter', [
      [200, 'Light'],
      [400, 'Regular'],
      [500, 'Medium'],
      [600, 'Bold'],
    ]),
    fontFaces('Pressura Mono', 'GT-Pressura-Mono', [
      [200, 'Light'],
      [400, 'Regular'],
      [600, 'Bold'],
    ]),
  ].join('\n')
  return <Global styles={`html { -webkit-font-smoothing: antialiased; } ${allFonts} `} />
}
